.content4{
    width: 100%;
    height: 52.1rem;
    display: flex;
    align-items: center;
    justify-content: center;    
}

.content4 .main{
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: black; */
    /* height: 318px; */
    height: auto;
    /* width: 1212px; */
    width: 80%;
    /* flex-wrap: wrap; */
}

.content4 .main .box{
    height: 31.8rem;
    background: #F5F5F5;
    width: 38.4rem;
    position: relative;
    transition: 0.2s ease-in;
    /* margin-left: 2%; */
    margin: .2rem;
}

.content4 .main .box:hover{
    background: #5772FF;
}


.content4 .main .box1 .top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 38%;
    /* width: auto; */
    /* position: absolute; */
    position: absolute;
    left: -.4rem;
    top: 3.6rem;    
}
.content4 .main .box2 .top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 56%;
    /* width: auto; */
    /* position: absolute; */
    position: absolute;
    left: -.4rem;
    top: 3.6rem;      
}
.content4 .main .box3 .top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 48%;
    /* width: auto; */
    /* position: absolute; */
    position: absolute;
    left: -.4rem;
    top: 3.6rem;      
}

/* Arrow */
.content4 .main .box .top .fa-play{
/* position: absolute; */
width: 2.6rem;
height: 2.2rem;
/* left: -4px;
top: 36px; */
color: white;
/* width: 100%; */
}

/* Heading of the boxes */
.content4 .main .box .top .heading{
    color: #4D0043;
    font-family: Lato;
    font-size: 2rem;
    font-weight: 800;
    line-height: 2.8rem;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    top: .6rem;
}

.content4 .main .box:hover .top .heading{
    color: white;
}

/* Content of the boxes */
.content4 .main .box .bottom{
    position: absolute;
    /* width: 32.9rem; */
    width: 80%;
    /* height: 21.4rem; */
    height: 80%;
    left: 2.9rem;
    top: 8.7rem;
    
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 140%;
    /* or 20px */
    
    
    /* caption */
    
    color: #898B92;    
}

.content4 .main .box:hover .bottom{
    color: white;
}

/* .content4 .main .box .fa-play{
position: absolute;
width: 26px;
height: 22px;
left: -4px;
top: 36px;
color: white;
} */


/* Responsive */
@media screen and (max-width : 1400px){
    .content4 .main .box{
        width: 32%;
    }

    .content4 .main .box1 .top{
        width: 15.5rem;
    }

    .content4 .main .box2 .top{
        width: 22.5rem;
    }
    .content4 .main .box3 .top{
        width: 19.4rem;
    }

    .content4 .main .box .bottom{
        left: 3.9rem;
    }
}

@media screen and (max-width : 800px){
    .content4{
        height: 120rem;
    }

    .content4 .main{
        /* display : block; */
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 100%;
        /* height: 114.6rem; */
        width: 100%;
        position: relative;
        top: 3%;
    }

    .content4 .main .box{
        width: 60%;
    }
    
    .content4 .main .box1 .top{
        width: 17.3rem;
    }

    .content4 .main .box2 .top{
        width: 25.3rem;
    }
    .content4 .main .box3 .top{
        width: 21.7rem;
    }


    .content4 .main .box .top .heading{
        font-size: 16px;
    }

    .content4 .main .box .bottom{
        font-size: 14px;
        /* left: 4.5rem; */
    }


}

@media screen and (max-width : 480px){
    .content4{
        height: 120rem;
        
    }

    .content4 .main{
        position: relative;
        top: 5%;
    }

    .content4 .main .box{
        /* width: 80%; */
        width: 320px;
        height: 250px;
    }

    .content4 .main .box1 .top{
        width: 19.3rem;
        left: -1.4rem;
        top: 2.6rem;
    }

    .content4 .main .box2 .top{
        width: 27.3rem;
        left: -1.4rem;
        top: 2.6rem;
    }
    .content4 .main .box3 .top{
        width: 23.7rem;
        left: -1.4rem;
        top: 2.6rem;
    }

    .content4 .main .box .top .heading{
        font-size: 16px;
    }

    .content4 .main .box .bottom{
        font-size: 14px;
        left: 5.5rem;
        top: 10rem;
    }
}
.nav{
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 0%;
  left: 0%;
  flex-wrap: wrap;
  height: 12rem;  
}

.nav_colorChange{
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 0%;
  left: 0%;
  flex-wrap: wrap;
  height: 12rem;
  background-color: black;
  z-index: 3;
  /* transition: 1s ease-in-out; */
}

.outer{
  /* position: absolute; */
  /* top: 0%; */
  /* left: 0%; */
  width: 90%;
  
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* flex-wrap: wrap; */
}

.outer .companylogo{
  max-width: 100%;
}

.outer .companylogo img:nth-child(2){
  display: none;
}

.outer .inner{
  display: flex;
  align-items: center;
  justify-content: space-between;  
  width: 40%;
  /* width: 51.6rem; */
  /* flex-wrap: wrap; */
}

.outer .inner li{
  list-style: none;
  margin: 0.5rem;
}

/* .outer .inner li:nth-child(1) a{
  color: #5772FF;
} */

.outer .inner .inActive a.active{
  color: #ffffff;
}

.outer .inner a.active{
  color: #5772FF;
}

.outer .inner li a{
  text-decoration: none;
  font-family: Lato;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.2rem;
  color: #FFFFFF;
}

.outer .phn{
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 16.5rem;
  cursor: pointer;
}

.outer .hamburger{
  display: none;
}

.outer .phn div:nth-child(1){
  width: 1.796rem;
  /* width: 20%; */
}

.outer .phn div:nth-child(2){
  width: 13.6rem;
  /* width: 70%; */
}

.outer .phn div:nth-child(2) a{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 120%;
  color: #5772FF;
  text-decoration: none;
}

.outer .phn div:nth-child(2):hover a{
  color: #F31237;
}

.outer .login a{
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 20.8rem;
  padding: 1.6rem;
  border: .2rem solid #FFFFFF;
  border-radius: 4.5rem;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 120%;
  text-decoration: none;
  color: #FFFFFF;
}

.outer .login a:hover{
  background-color: #FFFFFF;
  color: black;
}

.outer .login a:hover .icon{
  fill : black;
}

.outer .login div:nth-child(2){
  color: #FFFFFF;
}

.outer .activenavmenu{
  position: absolute;
  /* position: fixed; */
  display: block;
  width: 100%;
  height: 66.1rem;
  background: #4D0043;
  top: 90%;
  /* top: 12%; */
  transition: left 1s;
  left: 0;
  z-index: 1;
}

.outer .activenavmenu .menuitems{
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* justify-content: space-evenly; */
  height: 100%;
}

.outer .activenavmenu .menuitems a{
  width: 74px;
  height: 19px;
  
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  
  color: #FFFFFF; 
  text-decoration: none;
}

.outer .activenavmenu .menuitems li{
  padding: 3rem;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.outer .activenavmenu .menuitems .whatsapp{
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background: #5772FF;
  margin-left: 3rem;
}

.outer .activenavmenu .menuitems img{
  width: 20px;
  height: 19.08px;
}

.outer .navmenu{
  display: none;
  left: -100px;
}

/* Responsiveness */

@media screen and (max-width : 870px){
  .nav .outer{
    width: 100%;
  }

  .nav_colorChange .outer{
    width: 100%;
  }
}

@media screen and (max-width : 665px){
  .nav, .nav_colorChange{
    background: #FFFFFF;
    /* height: 58px */
  }

  .nav_colorChange .inner{
    display: none;
  }

  .nav_colorChange .phn{
    display: none;
  }

  .nav .outer{
    width: 90%;
    /* width: 100%; */
    justify-content: space-between;
  }

  .nav_colorChange .outer{
    width: 90%;
    justify-content: space-between;
  }

  .outer .companylogo img:nth-child(1){
    display: none;
  }

  .outer .companylogo img:nth-child(2){
    display: block;
  }

  .nav .outer .inner{
    display: none;
  }

  .nav .outer .phn{
    display: none;
  }

  .outer .hamburger{
    display: block;
    cursor: pointer;
  }
  
}

@media screen and (max-width : 545px){
  .nav_colorChange{
    width: 100%;
    /* width: 50rem; */
    z-index: 3;
  }

  .nav_colorChange .activenavmenu .menuitems li{
    list-style : none;
  }
}

.blog{
    width: 100%;
    height: 121.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blog .main{
    width: 80%;
    height: 93rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.blog .main .left{
    display: block;
    width: 80rem;
     /* width: 66%; */
    height: 100%;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.blog .main .left a{
    text-decoration: none;
    height: 45.2rem;
    /* width: 38.8rem; */
    width: 49%;
    margin: .2rem;
}

.blog .main .left .box{
    /* flex: 0 48%;

    height: 45.2rem;
    
    width: 38.4rem;
    margin-bottom: 3%; */
    
}

.blog .main .left .box .top img{
    max-width: 100%;
}

.blog .main .left .box .bottom{
    width: 100%;
    /* height: 22.4rem; */
    height: 18.4rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.blog .main .left .box .bottom .datencmt{
    width: 18.8rem;
    height: 1.7rem;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 120%;
    /* identical to box height, or 17px */
    
    letter-spacing: 0.02em;
    
    /* caption */
    
    color: #898B92;    
}

.blog .main .left .box .bottom .blogheading{
    width: 24.1rem;
    height: 5.8rem;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 800;
    font-size: 2.4rem;
    line-height: 120%;
    /* or 29px */
    
    text-align: center;
    letter-spacing: 0.02em;
    
    /* text-color */
    
    color: #252B42;   
}

.blog .main .left .box .bottom .blogcontent{
    width: 27.9rem;
    height: 4.5rem;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 140%;
    /* or 20px */
    
    text-align: center;
    
    /* caption */
    
    color: #898B92;    
}


.blog .main .right{
    width: 38.4rem;
    /* width: 30%; */
    height: 100%;
    background: #F5F5F5;    
}

.blog .main .right .blogCategory{
    width: 10.1rem;
    height: 2.4rem;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 800;
    font-size: 2rem;
    line-height: 120%;
    /* identical to box height, or 24px */

    letter-spacing: 0.02em;
    margin-left: 3rem;
    /* text */

    color: #272C35;    
}

.blog .main .right a {
    text-decoration: none;
    /* width: 94px; */
    width: 17rem;
    height: 1.7rem;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 120%;
    /* identical to box height, or 17px */

    letter-spacing: 0.02em;

    /* caption */

    color: #898B92;
    /* display: flex;
    justify-content: space-around;
    align-items: center; */
}


.blog .main .right .blogcategories{
    /* margin-left: 4rem; */
    margin-left: 7rem;
    width: 9.4rem;
    height: 18rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.blog .main .right .hr{
    width: 36.8rem;
    /* width: 96%; */
    height: 0px;
    
    border: 0.01rem solid #E0E0E0;
}

.blog .main .right .search .searchHeading{
    width: 6.3rem;
    height: 2.4rem;
    
    font-family: 'Lato';
    font-style: normal;
    font-weight: 800;
    font-size: 2rem;
    line-height: 120%;
    /* identical to box height, or 24px */
    
    letter-spacing: 0.02em;
    
    /* text */
    
    color: #272C35;
    margin-left: 3rem;    
}

.blog .main .right .search input[type=text]{
    border: none;
    outline: 0;
    width: 100%;
}

.blog .main .right .search .searchBar{
    box-sizing: border-box;

    /* Auto layout */
    
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2.4rem 4rem;
    /* gap: 13rem; */

    /* width: 28.3rem; */
    width: 74%;
    height: 5.6rem;
    
    background: #FFFFFF;
    border: 0.1rem solid #E6E6E6;
    border-radius: 4.5rem;    

    margin-left: 3rem;
}

.blog .main .right .search .searchBar input ::placeholder{
    width: 7.1rem;
    height: 2.2rem;
    
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    /* font-size: 1.8rem; */
    /* font-size: 12%; */
    line-height: 120%;
    /* identical to box height, or 22px */
    
    text-align: center;
    
    /* caption */
    
    color: #898B92;   
}

.blog .main .right .search input:focus::-webkit-input-placeholder { color:transparent; }

.blog .main .right .search .searchBar .searchicon{
    display: flex;
    align-items: center;
    color: #5772FF;
    font-size: 1.8rem;
    /* margin-left: 3rem; */
}

.comingSoonBox{
    display: flex;
    justify-content: center;
    margin: 4rem;
}

.comingSoonBox img{
    width: 370px;
}

/* Responsiveness */
@media screen and (max-width : 1440px){
    .blog .main .left{
        width: 68%;
    }

    .blog .main .right{
        width: 30%;
    }

    .blog .main .right .hr{
        width: 96%;
    }

    .blog .main .left a{

    }


}

@media screen and (max-width : 1000px){
    .blog .main{
        width: 95%;
    }
}

@media screen and (max-width : 700px){
    .blog .main .right .search .searchBar ::-webkit-input-placeholder { 
        color: #fff;
        /* font-size: 10px; */
        /* font-size: 2rem; */
    }

    .blog .main{
        width: 99%;
    }
}

@media screen and (max-width : 600px){
    .blog .main{
        /* height: 116rem; */
        height: 180rem;
        flex-direction: column;
    }

    .blog{
        height: 185rem;
    }

    .blog .main .left{
        width: 90%;
    }

    .blog .main .right{
        width: 60%;
    }

    .blog .main .right .blogCategory{
        font-size: 18px;
    }

    .blog .main .right .blogcategories{
        margin-left: 11rem;
    } 

    .blog .main .right a{
        font-size: 14px;
        width: 25rem;
    } 

    .blog .main .right .search .searchHeading{
        font-size: 18px;
    }

    .blog .main .right .search .searchBar ::-webkit-input-placeholder { 
        color: #898B92; 
        /* font-size: 10px; */
        /* font-size: 2rem; */
    }
}

@media screen and (max-width : 480px){

    .blog{
        height: 292rem;
    }

    .blog .main{
        height: 285rem;
    }

    .blog .main .left{
        width: 100%;
        height: 71%;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
    }

    .blog .main .left a{
        /* width: 65%; */
        width: 80%;
    }

    .blog .main .left .box .bottom .blogheading{
        width: 38.1rem;
        height: 3.8rem;
    }

    .blog .main .left .box .bottom .datencmt{
        /* width: 24.8rem;
        font-size: 14px; */
        /* width: 188px; */
        width: 100%;
        height: 17px;

        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 120%;
        text-align: center;
        letter-spacing: 0.02em;

        color: #898B92;
    } 

    .blog .main .left .box .bottom .blogcontent{
        width: 34.9rem;
        font-size: 14px;
    }

    .blog .main .right{
        width: 80%;
        height: 543px;
    }

    .blog .main .right .blogCategory{
        font-size: 20px;
    }

    .blog .main .right .blogcategories{
        margin-left: 11rem;
    }
    
    .blog .main .right .search .searchHeading{
        font-size: 20px;
    } 

    .blog .main .right a{
        font-size: 12px;
        width: 25rem;
    } 
}
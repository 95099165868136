* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* .App {
  border-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
} */

/* @media screen and (max-width : 480px){ */
  .App{
    overflow-x: hidden !important;
  }
/* } */
/* 1px = 6.25%
1rem = 10px */

html{
    font-size: 62.5%;
}

@media(max-width : 998px){
    html{
        font-size: 55%;
    }
}

@media(max-width : 768px){
    html{
        font-size: 45%;
    }
}
.quotes{
    width: 100%;
    height: 65.8rem;
    
    display: flex;
    align-items: center;
    justify-content: center;
}

.quotes .main{
    width: 100%;
    height: 52.1rem;
    background: linear-gradient(0deg, rgba(77, 0, 67, 0.9), rgba(77, 0, 67, 0.9)),url('../assests/factory-automation-with-automated-guided-vehicles-robotic-arm-transportation-increase-transport-more-with-safety.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    color: white;
    justify-content: center;
}

.quotes .main .box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 30rem;
}


/* .quotes .main .heading, .content, .button{
    
} */

.quotes .main .top{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    height: 6.5rem;
}

.quotes .main .top .heading{
    width: 43.1rem;
    height: 4.5rem;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 800;
    font-size: 4rem;
    line-height: 120%;    
    text-align: center;
}

.quotes .main .top .bar{
    width: 9.4rem;
    height: .3rem;
    background: #FFFFFF;
}

.quotes .main .content{
    width: 62.4rem;
    height: 8.5rem;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 140%;
    /* or 20px */
    
    text-align: center;
    letter-spacing: 0.02em;    
}

.quotes .main .button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1.6rem 4rem;
    gap: 8px;
    background: #FFFFFF;
    border-radius: 4.5rem;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 120%;
    /* identical to box height, or 22px */
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    
    /* Primary */
    
    color: #4D0043;
    flex: none;
    order: 0;
    flex-grow: 0;    
    cursor: pointer;        
}

@media screen and (max-width : 480px){
    .quotes .main{
        width: 100%;
        height: 314px;
    }

    .quotes .main .content{
        width: 49rem;
    }

    .quotes .main .button{
        background: #5772FF;
        color: #FFFFFF;
    }
}
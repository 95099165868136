.clients{
    width: 100%;
    height: 37rem;
    display: flex;
    align-items: center;
    justify-content: center;

}

.clients .main{
    width: 80%;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.clients .main .top{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;    
    height: 55px;
    position: relative;
    top: 3rem;   
}

.clients .main .top .heading{
    width: 38.1rem;
    height: 3.8rem;
    
    font-family: 'Lato';
    font-style: normal;
    font-weight: 800;
    font-size: 3.2rem;
    line-height: 120%;
    /* or 38px */
    
    letter-spacing: 0.02em;
    
    /* text */
    
    color: #272C35;  
}

.clients .main .top .bar{
    width: 9.4rem;
    height: .3rem;    
    /* Primary */
    
    background: #4D0043;   
}

.clients .main .bottom{
    width: 100%;
    height: 58%;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    flex-wrap: wrap;
    position: relative;
    cursor: pointer;
}

.clients .main .bottom .mySwiper-mobile{
    display: none;
}

.clients .main .bottom .mySwiper .swiper-button-prev{
    /* display: none; */
}

.clients .main .bottom .mySwiper .swiper-button-next{
    /* display: none; */
}

.clients .main .bottom .mySwiper .swiper-slide .swiper-slide-duplicate .swiper-slide-prev{
    width: 440.667px;
    margin-right: 20px;    
}

.clients .main .bottom .mySwiper .swiper-slide .swiper-slide-active{
    
}
.clients .main .bottom .mySwiper .swiper-slide .swiper-slide-prev{
    
}
.clients .main .bottom .mySwiper .swiper-slide .swiper-slide-next{
    
}

.clients .main .bottom .mySwiper .logo4{
    position: relative;
    /* bottom: 0.2rem; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.clients .main .bottom .mySwiper .logo6{
    position: relative;
    /* right: 7rem; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* bottom: 2rem; */
}

.clients .main .bottom .mySwiper .logo7,.logo1,.logo2,.logo3{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    /* bottom: 3rem; */
}

.swiperlogoBox{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 175px;
}

.swiperlogoBox img{
    max-height: 120px;
    max-width: 200px;
}

/* Responsive */

@media screen and (max-width : 850px){
    .map{
        width: 100%;
        height: 55rem;
    }

    .clients{
        /* height: 65rem; */
        height: 55rem;
    }

    .clients .main{
        height: 40rem;
    }

    .clients .main .top{
        top: 0;
    }

    .clients .main .bottom{
        height: 80%;
    }
}


@media screen and (max-width : 480px){
    .clients .main .bottom{
        height: 80%;
        /* flex-wrap: nowrap; */
    }
    

    .clients .main .top{
        width: 100%;

    }

    .clients .main .top .heading{
        /* width: 234px; */
        width: 100%;
        height: 24px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        text-align: center;
        line-height: 120%;
        letter-spacing: 0.02em;
        color: #252B42;        
    }

    .clients .main .bottom .mySwiper{
        display: none;
    }

    .clients .main .bottom .mySwiper-mobile{
        display: block;
    }

    .clients .main .bottom .logo1 img{
        /* width: 100px;
        height: 100px; */
        width: 18rem;
        height: 15rem;
    }
    .clients .main .bottom .logo2 img{
        width: 15rem;
        height: 15rem;
    }
    .clients .main .bottom .logo3 img{
        width: 20rem;
        height: 15rem;
    }
    .clients .main .bottom .logo4 img{
        width: 18rem;
        height: 15rem;
    }
}
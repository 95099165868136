.map{
    width: 100%;
    height: 65.8rem;
}

@media screen and (max-width : 650px){
    .map{
        width: 100%;
        height: 55rem;
    }
}


@media screen and (max-width : 480px){
    .map{
        height: 235px;
    }
}
.careers {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* height: 121.9rem; */
    margin: 5rem 0;
}

.careers .main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* height: 99rem; */
    width: 80%;
}

.careers .main .top{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;    
    height: 5.5rem;   
    position: relative;
    margin-bottom: 3rem;
}

.careers .main .top .heading{
    width: 34.4rem;
    height: 3.8rem;
    
    font-family: 'Lato';
    font-style: normal;
    font-weight: 800;
    font-size: 3.2rem;
    line-height: 120%;
    /* or 38px */
    
    letter-spacing: 0.02em;
    
    /* text-color */
    
    color: #252B42;   
}

.careers .main .top .bar{
    width: 9.4rem;
    height: .3rem;
    
    /* Primary */
    
    background: #4D0043;   
}

.careers .main .bottom{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.careers .main .bottom a {
    width: 32%;
    height: 23.9rem;
    padding-bottom: 18%; /* 32:18, i.e. 16:9 */
    margin-bottom: 3.5%;
    /* background-color: red; */
    background: #F5F5F5;
    text-decoration: none;
    position: relative;
    color: white;
}

.careers .main .bottom a .box{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.careers .main .bottom a .box .boxtop{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 72%;
    /* position: absolute; */
    position: absolute;
    left: -0.4rem;
    top: 3.6rem;
}

.careers .main .bottom a .box .boxtop .boxheading{
    width: 24.5rem;
    height: 2.8rem;
    
    font-family: 'Lato';
    font-style: normal;
    font-weight: 800;
    font-size: 2rem;
    line-height: 140%;
    /* identical to box height, or 28px */
    
    
    /* Primary */
    
    color: #4D0043;   
}

.careers .main .bottom a .box .boxbottom{
    position: absolute;
    /* width: 32.9rem; */
    width: 80%;
    /* height: 21.4rem; */
    height: 80%;
    left: 2.9rem;
    top: 8.7rem;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 140%;
    color: #898B92;
}

/* .careers .main .bottom a .box{
    width: 32%;
    padding-bottom: 18%; 
    margin-bottom: 3.5%;
    background-color: red;
    height: 23.9rem;
} */


/* Responsiveness */
@media screen and (max-width : 1410px){
    .careers .main .bottom a .box .boxtop{
        width: 27.5rem;
    }
    
}

@media screen and (max-width : 1200px){
    .careers .main .bottom a .box .boxtop{
        /* width:auto; */
    }
    
    
    .careers .main .bottom a .box .boxbottom{
        font-size: 1.2rem;
        /* font-size: 12px; */
    }

    .careers .main .bottom a .box .boxtop .boxheading{
        font-size: 1.8rem;
    } 
}

@media screen and (max-width : 800px) {
    /* .careers{
        height: 256rem;
    }

    .careers .main{
        height: 249rem;
    } */

    .careers .main .bottom{
        flex-direction: column;
    }

    .careers .main .bottom a{
        width: 80%;
    }
    
    .careers .main .bottom a .box .boxtop{
        width: 28.5rem;
        /* left: 3.9rem; */
    }

    .careers .main .bottom a .box .boxtop .boxheading{

    }

    .careers .main .bottom a .box .boxbottom{
        left: 3.9rem;
        font-size: 12px;
    } 
}

@media screen and (max-width : 500px) {
    .careers .main .bottom{
        flex-direction: column;
    }

    .careers .main .bottom a{
        width: 100%;
    }
}

@media screen and (max-width : 480px){
    /* .careers{
        height: 248rem;
    } */

    .careers .main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        /* height: 240rem; */
        width: 80%;
    }

    .careers .main .top{
        height: 4.5rem;
    }

    .careers .main .top .heading{
        width: 191px;
        height: 22px;
        
        font-family: 'Lato';
        font-style: normal;
        font-weight: 800;
        /* font-size: 18px; */
        font-size: 14px;
        line-height: 120%;
        /* identical to box height, or 22px */
        text-align: center;
        letter-spacing: 0.02em;
        
        /* text-color */
        
        color: #252B42;        
    }

    .careers .main .bottom a .box .boxtop{
        /* width: 75%; */
    }

    .careers .main .bottom a .box .boxbottom{
        font-size: 11.2px;
        /* left: 14%; */
    }
    
    .careers .main .bottom a .box .boxtop .boxheading{
        /* font-size: 14px; */
        font-size: 12px;

    }     
    
}


@media screen and (max-width : 380px){
    .careers .main .top .heading{
        width: 191px;
        height: 22px;
        
        font-family: 'Lato';
        font-style: normal;
        font-weight: 800;
        /* font-size: 18px; */
        font-size: 14px;
        line-height: 120%;
        /* identical to box height, or 22px */
        text-align: center;
        letter-spacing: 0.02em;
        
        /* text-color */
        
        color: #252B42;        
    }
}
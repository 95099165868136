.template{
    max-width: 100%;
    /* height: 79vh; */
    height: 58.4rem;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.71) -1.25%, rgba(0, 0, 0, 0) 145.9%), url('../assests/template.webp') no-repeat center center /cover;
    display: flex;
    align-items: center;
    justify-content: center;
}


.template .main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 25%;
}

.template .main .top{
    width: 108.3rem;
    height: 7.4rem;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 800;
    font-size: 7.2rem;
    line-height: 110%;
    /* or 79px */

    text-align: center;
    letter-spacing: 0.02em;

    color: #FFFFFF;
}

.template .main .bottom{
    /* width: 49rem; */
    width: 51rem;
    height: 4rem;
    
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 110%;
    /* or 26px */
    
    text-align: center;
    letter-spacing: 0.02em;
    
    color: #FFFFFF;  
}

.template .main .bottom a{
    text-decoration: none;
    color: white;
}

/* Responsiveness */
@media screen and (max-width : 480px){
    .template .social_media{
        /* right: 36.9%; */
        right: 0;
        /* top: 35%; */
        top: 170px;
        z-index: 3;
    }

    .template{
        width: 100%;
        /* height: 50rem; */
        height: 350px;
    }

    .template .main{
        /* height: 35%; */
        height: 26%;
        position: relative;
        top: 10%;
    }

    .template .main .top{
        font-size: 40px;
    }
}
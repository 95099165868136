.dropmessage{
    width: 100%;
    height: 72.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropmessage .main{
    width: 60%;
    height: 54rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.dropmessage .main .top{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;    
    height: 7.8rem;   
    position: relative;
    top: 1rem;
}

.dropmessage .main .top .heading{
    width: 37.4rem;
    height: 5.8rem;
    
    font-family: 'Lato';
    font-style: normal;
    font-weight: 800;
    font-size: 4.8rem;
    line-height: 120%;
    /* identical to box height, or 58px */
    
    letter-spacing: 0.02em;
    
    /* text-color */
    
    color: #252B42;
}

.dropmessage .main .top .bar{
    width: 9.4rem;
    height: .3rem;
    
    /* Primary */
    
    background: #4D0043; 
}

/* Form */

.dropmessage .main .bottom{
    width: 100%;
    height: 40rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.dropmessage .main .bottom .nameandemail{
    /* width: 80%; */
    width: 71.6rem;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dropmessage .main .bottom .nameandemail .name{
    box-sizing: border-box;

    /* Auto layout */
    
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2.4rem;
    gap: .8rem;
    
    width: 34.9rem;
    height: 5.6rem;
    
    border: .1rem solid #E6E6E6;
    border-radius: 4.5rem;    
}

.dropmessage .main .bottom .nameandemail .email{
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2.4rem;
    gap: .8rem;
    
    width: 34.9rem;
    height: 5.6rem;
    
    border: .1rem solid #E6E6E6;
    border-radius: 4.5rem;    
}

.dropmessage .main .bottom .messageText{
    box-sizing: border-box;

    /* Auto layout */
    
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 2.4rem;
    gap: .8rem;
    
    width: 72rem;
    height: 23.2rem;
    
    border: .1rem solid #E6E6E6;
    border-radius: 2.4rem;    
}

.dropmessage .main .bottom .messagebox{
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.dropmessage .main .bottom .sendmsg{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 1.6rem 4rem;
    gap: .8rem;
    
    /* width: 24.2rem; */
    width: 22.2rem;
    height: 5.4rem;
    /* Primary */

    background: #4D0043;
    border-radius: 4.5rem;
}

.dropmessage .main .bottom .sendmsg span{
    width: 13.6rem;
    height: 2.2rem;
    
    font-family: 'Lato';
    font-style: normal;
    font-weight: 800;
    font-size: 1.8rem;
    line-height: 120%;
    /* identical to box height, or 22px */
    
    text-align: center;
    text-transform: uppercase;
    
    /* Screen Color */
    
    color: #FFFFFF;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 0;
    flex-grow: 0;   
}

/* Placeholder */
input ::placeholder, ::placeholder{    
    /* Headline/Headline 5 */
    width: 77px;
    height: 22px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    /* identical to box height, or 22px */
    
    text-align: center;
    
    /* caption */
    
    color: #898B92;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 0;
    flex-grow: 0;    
}

.name::placeholder{
    /* width: 55px; */
    width: 100%;
    height: 22px;
    text-align: left;
}

.email::placeholder{
    /* width: 59px; */
    width: 100%;
    height: 22px;
    text-align: left;
}

.messageText::placeholder{
    width: 100%;
    text-align: left;
}

/* input[type="text"]::placeholder {
    text-align: left;
} */

/* Responsive  */

@media screen and (max-width : 650px){
    .dropmessage .main{
        /* width: 30%; */
        height: 65rem;
    }

    .dropmessage .main .bottom{
        height: 50rem;
    }

    .dropmessage .main .bottom .nameandemail{
        flex-direction: column;
        height: 15rem;
        justify-content: space-between;
    }

    .dropmessage .main .bottom .messageText{
        width: 100%;
        /* width: 40rem; */
    }
}

@media screen and (max-width : 480px){
    .dropmessage .main .bottom .nameandemail .name{
        width: 65%;
    }

    .dropmessage .main .bottom .nameandemail .email{
        width: 65%;
    }

    
    .dropmessage .main .bottom #messageText{
        width: 45rem;
    }

    .dropmessage .main .top{
        height: 5.8rem;
    }

    .dropmessage .main .top .heading{
        width: 176px;
        height: 24px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 120%;
        /* identical to box height, or 24px */

        letter-spacing: 0.02em;

        /* text-color */

        color: #252B42;
        text-align: center;
    }

    .dropmessage .main .bottom .messagebox {
        /* width: 100%; */
        width: 65vw;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .dropmessage .main .bottom .sendmsg{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px;
        gap: 8px;

        width: 144px;
        height: 40px;

        /* Secondary */

        background: #5772FF;
        border-radius: 27px;
        border: none;
    }

    .dropmessage .main .bottom .sendmsg span{
        width: 104px;
        height: 16px;

        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        /* font-size: 14px; */
        font-size: 12px;
        line-height: 130%;
        /* or 18px */

        text-align: center;

        /* Screen Color */

        color: #FFFFFF;
    }
}


@media screen and (max-width : 420px){
    .dropmessage .main .bottom .messagebox{
        width: 75vw;
    }
}

@media screen and (max-width : 360px){
    .dropmessage .main .bottom .messagebox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    .dropmessage .main .bottom .messagebox{
        position: relative;
        left: -22%;
    }
}
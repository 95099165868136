.whatourclientsays{
    width: 100%;
    height: 97.2rem;
    background: #F5F5F5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.whatourclientsays .main{
    width: 100%;
    height: 74.8rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.whatourclientsays .main .top{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;    
    height: 5.5rem;   
}

.whatourclientsays .main .top .heading{
    width: 39.7rem;
    height: 3.8rem;
    
    font-family: 'Lato';
    font-style: normal;
    font-weight: 800;
    font-size: 3.2rem;
    line-height: 120%;
    /* or 38px */
    
    text-transform: uppercase;
    
    /* text-color */
    
    color: #252B42;  
}

.whatourclientsays .main .top .bar{
    width: 9.4rem;
    height: .3rem;    
    /* Primary */
    
    background: #4D0043;   
}

/* inside whatourclientsays -> main -> bottom */

.whatourclientsays .carousel {
    display: flex;
    height: 31.8rem;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  
.whatourclientsays .carousel_wrapper {
    position: relative;
    width: 80%;
    height: 100%;
  }
  
.whatourclientsays .carousel_wrapper .slide{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

.whatourclientsays  .carousel_wrapper .slide .content{
    width: 50%;
    height: 100%;
    background-color: white;
  }

.whatourclientsays  .carousel_wrapper .slide .content .contentbox{
    padding-top: 2rem;
    padding-left: 5rem;
    width: 100%;
    height: 18.2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .whatourclientsays  .carousel_wrapper .slide .content .contentbox .title{
    display: flex;
    /* width: 44%; */
    width: 24.7rem;
    justify-content: space-between;
    position: relative;
    left: -5%;
  }

  .whatourclientsays  .carousel_wrapper .slide .content .contentbox .text{
    width: 51.8rem;
    height: 8rem;
    
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 140%;
    /* or 20px */
    
    
    /* caption */
    
    color: #898B92;  
  }

.whatourclientsays  .carousel_wrapper .slide .content .contentbox .nameandcountry{
    display: flex;
    /* width: 29%; */
    width: 15rem;
    justify-content: space-between;
  }

 .whatourclientsays  .carousel_wrapper .slide .content .contentbox .name{
  width: 7.2rem;
  height: 2rem;
  
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 140%;
  /* identical to box height, or 20px */
  
  
  /* Secondary */
  
  color: #5772FF;   
 }  

 .whatourclientsays  .carousel_wrapper .slide .content .contentbox .country{
  width: 7.6rem;
  height: 2rem;
  
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 140%;
  /* identical to box height, or 20px */
  
  
  /* caption */
  
  color: #898B92;  
 }

.whatourclientsays  .carousel_wrapper .slide .content .heading{
    width: 21.8rem;
    height: 2.8rem;

    font-family: 'Lato';
    font-style: normal;
    font-weight: 800;
    font-size: 2rem;
    line-height: 140%;
    /* identical to box height, or 28px */
    
    
    /* Primary */
    
    color: #4D0043;  
  }

.whatourclientsays  .carousel_card {
    display: flex;
    flex: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    /* border-radius: 20px; */
    /* -webkit-box-shadow: 0px 0px 11px 3px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 11px 3px rgba(0, 0, 0, 0.15); */
    opacity: 0;
    pointer-events: none;
    /* transform: scale(0.8); */
    /* transition: 0.5s ease-in-out; */
  }
  
.whatourclientsays  .carousel_card-active {
    opacity: 1;
    /* transform: scale(1); */
    pointer-events: visible;
  }
  
.whatourclientsays  .card_image {
    width: 50%;
    height: 100%;
    object-fit: cover;
  }
  
.whatourclientsays  .card_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    padding: 4rem 3rem;
    /* align-items: flex-end; */
  }
  

.whatourclientsays  .carousel_arrow_left,
.whatourclientsays  .carousel_arrow_right {
    position: absolute;
    font-size: 4rem;
    top: 50%;
    transform: translate(0, -50%);
    background-color: gainsboro;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    padding-bottom: .7rem;
    cursor: pointer;
  }
  
.whatourclientsays  .carousel_arrow_left {
    left: 1.5rem;
  }
  
 .whatourclientsays .carousel_arrow_right {
    right: 1.5rem;
  }
  
.whatourclientsays  .carousel_pagination {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translate(-50%, 0);
  }
  
.whatourclientsays  .pagination_dot {
    height: 1.6rem;
    width: 1.6rem;
    background: black;
    background: #A7A7A7;
    border-radius: 50%;
    display: inline-block;
    margin-left: 1rem;
    cursor: pointer;
  }
  
.whatourclientsays .pagination_dot:hover {
    transform: scale(1.2);
  }
  
.whatourclientsays .pagination_dot-active {
    background-color: blue;
  }


@media screen and (max-width : 1440px){
  .whatourclientsays .carousel_wrapper .slide img{
    max-width: 100%;
    max-height: 100%;
  }

  .whatourclientsays .carousel_wrapper .slide .content .contentbox{
    height: 20rem;
  }

  .whatourclientsays .carousel_wrapper .slide .content .contentbox .text{
    width: 90%;
    height: 50%;
  }
}


@media screen and (max-width : 1010px){
  .whatourclientsays .carousel_wrapper .slide .content .contentbox{
    height: 25rem;
  }
}

@media screen and (max-width : 620px){
  .whatourclientsays .carousel_wrapper .slide .content .contentbox{
    height: 30rem;
  }
}


@media screen and (max-width : 480px){
  .whatourclientsays .carousel{
    width: 100%;
    height: 448px;
  }

  .whatourclientsays .carousel_wrapper{
    width: 100%;
  }

  .whatourclientsays .main .bottom > div:nth-child(2){
    display: none;
  }

  .whatourclientsays .carousel_card-active{
    /* height: 100%; */
  } 

  .whatourclientsays .carousel_wrapper .slide{
    flex-direction: column;
    /* height: 100%; */
  }
  
  .whatourclientsays .carousel_wrapper .slide .content{
    width: 90%;
  }

    
  .whatourclientsays .carousel_wrapper .slide .content .heading{
    /* width: 26.8rem; */
    width: 30.8rem;
    font-size: 18px;
  }

  .whatourclientsays .carousel_wrapper .slide .content .contentbox{
    /* height: 26rem; */
    height: 28rem;
  }

  .whatourclientsays .carousel_wrapper .slide .content .contentbox .title{
    left: -10%;
    width: 35.7rem;
  } 
  
  .whatourclientsays .carousel_wrapper .slide .content .contentbox .text{
    font-size: 12px;
    height: 60%;
  }

  .whatourclientsays .carousel_wrapper .slide .content .contentbox .nameandcountry{
    /* width: 17rem; */
    width: 20rem;
  } 

  .whatourclientsays .carousel_wrapper .slide .content .contentbox .name{
    /* width: 9.2rem; */
    width: 100%;
    font-size: 12px;
  } 

  .whatourclientsays .carousel_wrapper .slide .content .contentbox .country{
    font-size: 12px;
  } 

  .whatourclientsays .card_image{
    width: 90%;
  } 
}

/* @media screen and (max-width : 500px) */


@media screen and (max-width : 356px){
  .whatourclientsays .carousel_wrapper .slide .content{
    width: 78%;
  }

  .whatourclientsays .card_image{
    width: 78%;
    height: 50%;
  }

  .whatourclientsays .carousel_wrapper .slide .content .contentbox .text{
    /* height: 50%; */
  }
}
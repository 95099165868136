.gallery .gallerymain{
    width: 100%;
    height: 1120px;
    /* height: 600px; */
    
    display: flex;
    align-items: center;
    justify-content: center;
}

.gallery .gallerymain .images{
    width: 80%;
    height: 90%;
}

/* Responsivenes */

@media screen and (max-width : 1500px){
    .gallery .gallerymain{
        height: 1800px;
    }   
}

@media screen and (max-width : 1040px){
    .gallery .gallerymain{
        height: 1900px;
    }  
}

@media screen and (max-width : 1020px){
    .gallery .gallerymain{
        height: 3200px;
    }  
}

/* @media screen and (max-width : 480px){

    .gallery .gallerymain{
        height: 2500px;
    }
    
} */

@media screen and (max-width : 368px){

    .gallery .gallerymain{
        height: 2500px;
    }
    
}


@media screen and (min-width : 370px) and (max-width : 480px){
    .gallery .gallerymain{
        height: 2700px;
    }
}
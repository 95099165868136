.wwr_outer{
width: 100%;
height: 65.8rem;
background: #F5F5F5;
display: flex;
align-items: center;
justify-content: space-between;
}

.wwr_outer .leftside{
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
}

.leftside .content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 1.6rem;
    
    width: 44.7rem;
    height: 30.7rem;
    /* left: calc(50% - 447px/2 - 376.5px);
    top: 80px;    */
}

.leftside .content .heading{
    width: 43.1rem;
    height: 5.8rem;
    
    font-family: 'Lato';
    font-style: normal;
    font-weight: 800;
    font-size: 4.8rem;
    line-height: 120%;
    /* identical to box height, or 58px */
    /* text-color */
    color: #252B42;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;    
}

.leftside .content .bar{
    width: 9.4rem;
    height: .3rem;

    /* Primary */

    background: #4D0043;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0
}

.leftside .content .text{
    width: 44.7rem;
    height: 21.4rem;
    
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 140%;
    /* or 20px */
    
    
    /* caption */
    
    color: #898B92;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 2;
    flex-grow: 0;
}

.wwr_outer .rightside{
    width: 60%;
}

.wwr_outer .rightside img{
    max-width: 100%;
}

/* Responsive */
@media screen and (max-width : 1200px) {

    .wwr_outer .leftside{
        width: 60%;
    }
}

@media screen and (max-width : 600px){
    .wwr_outer{
        /* height: auto; */
        height: 680px;
        flex-direction: column;
    }

    .wwr_outer .rightside{
        width: 100%;
        height: 100%;
        order: 1;
    }

    .wwr_outer .rightside img{
        width: 100%;
    }

    .wwr_outer .leftside{
        width: 100%;
        order: 2;
    }

    .leftside .content{
        height: 37.7rem;
    }

}


@media screen and (max-width : 480px){
    .leftside .content{
        height: 52rem;
    }

    .leftside .content .heading{
        font-size: 25px;
    }

    .leftside .content .bar{
        width: 94px;
        height: 2px;
    }

    .leftside .content .text{
        font-size: 1.9rem;
    }
}